import React from "react"
import { graphql } from "gatsby"

import { Trans, useTranslation } from "gatsby-plugin-react-i18next"
import Layout from "../components/layout"
import SEO from "../components/seo"

const TeamPage = () => {
  const { t, i18n } = useTranslation()

  return (
    <Layout>
      <SEO title={t("about_us")} />
      <section className="section has-bg-craft" id="who-are-you">
        <div className="container has-text-centered">
          <h1 className="title">
            <Trans>TEAM.WHO_ARE_YOU</Trans>
            {i18n.language === "en-gb" && (
              <img
                src="/images/questions.svg"
                className="img-questions"
                alt="question"
              />
            )}
          </h1>
        </div>
      </section>
    </Layout>
  )
}

export default TeamPage

export const query = graphql`
  query($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["common"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
